import React, { useContext } from 'react'
import { Callout as AlliumCallout, Link as AlliumLink } from '@telus-uds/components-web'
import BaseModal, { BaseModalProps } from '../BaseModal/BaseModal'
import { appendFieldPaths, pickDataAttrs } from '../../../utils/dataAttributes'
import { getIcon } from '@/siteBuilder/utils/getIcon'
import { TextRenderer } from '../../../utils/text/TextRenderer'
import { BlockRenderer } from '@/siteBuilder/renderer/contentful/BlockRenderer'
import { SiteBuilderContext } from '@/siteBuilder/renderer/context/SiteBuilderContext'
import useModalClick from '@/hooks/useModalClick'
import { intcmpProps, useIntcmpContent } from '@/hooks/useIntcmpContent'
import { CalloutContentWrapper } from './styles'

export type CalloutProps = {
  icon?: string
  text: string
  rounded?: boolean
  size?: 'small' | 'medium'
  iconVerticalAlign?: 'top' | 'middle' | 'bottom'
  textAlign?: 'left' | 'center'
  contentTextAlign?: 'left' | 'center'
  linkText?: string
  linkColour?: 'default' | 'alternative' | 'matchColour'
  url?: string
  intcmpRef?: intcmpProps
  target?: '_self' | '_blank' | '_parent' | '_top'
  baseModal?: BaseModalProps
  additionalClickHandler?: () => void
}

const Callout = ({
  icon,
  text,
  rounded,
  size = 'medium',
  iconVerticalAlign,
  textAlign,
  contentTextAlign = 'left',
  linkText,
  linkColour = 'default',
  url = '/',
  intcmpRef,
  target = '_self',
  baseModal,
  additionalClickHandler,
  ...rest
}: CalloutProps) => {
  let divNumber = 1
  const childFieldPath = [icon ? `.icon#div[${divNumber++}]` : null, `.text#div[${divNumber++}]`].filter(Boolean)

  // Used to append intcmp query params to the link (if defined). Called here to prevent lint errors since link is conditionally rendered.
  const intcmpContent = useIntcmpContent(url, linkText, intcmpRef)

  const { activeModal, brand } = useContext(SiteBuilderContext)
  const { tryModalClick } = useModalClick(url, baseModal)

  // Opens a modal on link click if defined, otherwise opens a link. Also handles additional onClick action if defined.
  const handleLinkClick = (e: MouseEvent) => {
    tryModalClick(e)
    additionalClickHandler && additionalClickHandler()
  }

  return (
    <>
      {baseModal && (
        <BaseModal
          id={baseModal.id}
          entryTitle={baseModal.entryTitle}
          modalMaxWidth={baseModal.modalMaxWidth}
          heading={<BlockRenderer block={baseModal.heading as any} />}
          subHeading={<BlockRenderer block={baseModal.subHeading as any} />}
          components={<BlockRenderer block={baseModal.components as any} />}
          isOpen={activeModal === baseModal.id}
          disableAnalytics={baseModal.disableAnalytics}
        />
      )}

      <AlliumCallout
        icon={getIcon(icon, brand)}
        variant={{ rounded, size }}
        verticalAlign={iconVerticalAlign}
        textAlignToFlex={textAlign}
        data-testid={'allium-callout-testid'}
        {...pickDataAttrs(appendFieldPaths(rest, childFieldPath))}
      >
        <CalloutContentWrapper textAlign={contentTextAlign}>
          <TextRenderer>{text}</TextRenderer>
          {linkText && (
            // Conditionally renders trailing link inline with text, separated with a space to give the appearance of a single block of text
            <>
              {' '}
              <AlliumLink
                onClick={handleLinkClick}
                href={intcmpContent}
                target={target}
                variant={{
                  size,
                  alternative: linkColour === 'alternative',
                }}
                tokens={{ color: linkColour === 'matchColour' ? 'inherit' : undefined }}
              >
                <TextRenderer>{linkText}</TextRenderer>
              </AlliumLink>
            </>
          )}
        </CalloutContentWrapper>
      </AlliumCallout>
    </>
  )
}

export default Callout
